<template>
  <div class="w-full">
    <div v-if="forgotPasswd == false" class="min-h-screen flex flex-col items-center justify-center bg-gray-300">
      <div class="flex flex-col bg-white shadow-md px-4 sm:px-6 md:px-8 lg:px-10 py-8 rounded-md w-full max-w-md">

        <div class="login-img">
          <img :src="image" alt="Cod In Africa" />
        </div>

        <div class="font-medium self-center text-xl sm:text-2xl uppercase text-gray-800">
          {{ $t('login_account') }}
        </div>

        <div class="relative mt-5 h-px bg-gray-300">
          <div class="absolute left-0 top-0 flex justify-center w-full -mt-2">
            <span class="bg-white px-4 text-xs text-gray-500 uppercase">{{ $t('login_with_email_username') }}</span>
          </div>
        </div>
        <div class="mt-10">
          <div>
            <div class="flex flex-col mb-6">
              <label for="email"
                class="mb-1 text-xs sm:text-sm tracking-wide text-gray-600">{{ $t('email_address') }}:</label>
              <div class="relative">
                <div class="inline-flex items-center justify-center absolute left-0 top-0 h-full w-10 text-gray-400">
                  <svg class="h-6 w-6" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                    viewBox="0 0 24 24" stroke="currentColor">
                    <path
                      d="M16 12a4 4 0 10-8 0 4 4 0 008 0zm0 0v1.5a2.5 2.5 0 005 0V12a9 9 0 10-9 9m4.5-1.206a8.959 8.959 0 01-4.5 1.207" />
                  </svg>
                </div>

                <input type="text" name="username" required
                  class="text-sm sm:text-base placeholder-gray-500 pl-10 pr-4 rounded-lg border border-gray-400 w-full py-2 focus:outline-none focus:border-green-500 "
                  v-model="formData.username" :placeholder="$t('username_or_email')" />
              </div>
            </div>

            <div class="flex flex-col mb-6">
              <label for="password"
                class="mb-1 text-xs sm:text-sm tracking-wide text-gray-600">{{ $t('password') }}:</label>
              <div class="relative">
                <div class="inline-flex items-center justify-center absolute left-0 top-0 h-full w-10 text-gray-400">
                  <span>
                    <svg class="h-6 w-6" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                      viewBox="0 0 24 24" stroke="currentColor">
                      <path
                        d="M12 15v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2zm10-10V7a4 4 0 00-8 0v4h8z" />
                    </svg>
                  </span>
                </div>

                <input type="password" name="password" @keyup.enter="trigger" v-model="formData.password"
                  class="text-sm sm:text-base placeholder-gray-500 pl-10 pr-4 rounded-lg border border-gray-400 w-full py-2 focus:outline-none focus:border-green-500 "
                  :placeholder="$t('password')" />
              </div>
            </div>

            <div class="flex items-center mb-4 -mt-4">
              <div class="flex ml-auto">
                <button @click="OpenPopUp"
                  class="inline-flex items-center font-bold text-green-500  hover:text-green-600 text-xs text-center focus:outline-none">
                  <span class="ml-2">{{ $t('forgot_password') }}</span>
                </button>
              </div>
            </div>

            <div class="flex w-full">
              <button @click="login" ref="sendReply"
                class="flex relative loidingconfirm items-center justify-center focus:outline-none text-white text-sm sm:text-base bg-green-700 hover:bg-green-700  rounded py-2 w-full transition duration-150 ease-in"
                :disabled="loading">
                <loading :active.sync="loading" :is-full-page="fullPage"></loading>
                <span class="mr-2 uppercase">{{ $t('login') }}</span>
                <span>
                  <svg class="h-6 w-6" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                    viewBox="0 0 24 24" stroke="currentColor">
                    <path d="M13 9l3 3m0 0l-3 3m3-3H8m13 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                  </svg>
                </span>
              </button>
            </div>
          </div>
        </div>
        <div class="flex justify-center items-center mt-6">
          <button @click="landingPage" target="_blank"
            class="inline-flex items-center font-bold text-green-500  hover:text-green-600 text-xs text-center focus:outline-none">
            <span>
              <svg class="h-6 w-6" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                viewBox="0 0 24 24" stroke="currentColor">
                <path d="M18 9v3m0 0v3m0-3h3m-3 0h-3m-2-5a4 4 0 11-8 0 4 4 0 018 0zM3 20a6 6 0 0112 0v1H3v-1z" />
              </svg>
            </span>
            <span class="ml-2">{{ $t('dont_have_account') }}</span>
          </button>
        </div>
        <vue-modal-2 name="myModal" :headerOptions="{ title: 'Find your email address' }" :noFooter="true" modalSize="lg"
          @on-close="closePopUp('myModal')">

          <div v-if="valideUser == false" class="modal-footer px-5 border0-t">
            <div class="items-center justify-center flex rounded-full">
              <span class="w-1/4 text-sm text-white inline-flex items-center justify-center">
                <img alt="..." class="w-full align-middle border p-3 rounded-full" src="../assets/logo.jpg" />
              </span>
            </div>
            <div class="flex flex-col mb-6">
              <div class="mt-10 relative">
                <div class="inline-flex items-center justify-center absolute left-0 top-0 h-full w-10 text-gray-400">
                  <span class="material-icons">email</span>
                </div>

                <input type="text" name="emailAddress"
                  class="text-sm sm:text-base placeholder-gray-500 pl-10 pr-4 rounded-lg border border-gray-400 w-full py-2 focus:outline-none focus:border-green-500 "
                  v-model="formData.forgotPasswdEmail" :placeholder="$t('enter_email_address')" />
              </div>
            </div>

            <button @click="closePopUp('myModal')"
              class="flex mt-6 mb-2 text-white mx-auto max-w-sm rounded text-center bg-gray-500 py-2 px-8 items-center focus:outline-none md:float-right">
              {{ $t('cancel') }}
            </button>
            <button @click="forgotPasswordUser"
              class="flex mt-6 mb-2 mr-2 text-white mx-auto max-w-sm rounded text-center bg-green-600  py-2 px-8 items-center focus:outline-none md:float-right">
              {{ $t('send_verification_code') }}
            </button>
          </div>

          <div v-else class="modal-footer px-5 border0-t">
            <div class="items-center justify-center flex rounded-full">
              <span class="w-1/4 text-sm text-white inline-flex it ems-center justify-center">
                <img alt="..." class="w-full align-middle border p-3 rounded-full" src="../assets/logo.jpg" />
              </span>
            </div>
            <div class="flex flex-col mb-6">
              <div class="mt-2 relative">
                <div class="inline-flex items-center justify-center absolute left-0 top-0 h-full w-10 text-gray-400">
                  <span class="material-icons">lock_outline</span>
                </div>
                <input type="text" name="verification code"
                  class="text-sm sm:text-base placeholder-gray-500 pl-10 pr-4 rounded-lg border border-gray-400 w-full py-2 focus:outline-none focus:border-green-500 "
                  v-model="formData.verify.code" :placeholder="$t('enter_code')" />
              </div>
            </div>

            <button @click="closePopUp('myModal')"
              class="flex mt-6 mb-2 text-white mx-auto max-w-sm rounded text-center bg-gray-500 py-2 px-8 items-center focus:outline-none md:float-right">
              {{ $t('cancel') }}
            </button>
            <button @click="validateVerificationCode" :disabled="loadingVerify"
              class="flex mt-6 mb-2 mr-2 text-white mx-auto max-w-sm rounded text-center bg-green-600  py-2 px-8 items-center focus:outline-none md:float-right relative loidingconfirm">
              <loading :active.sync="loadingVerify" :is-full-page="fullPage"></loading>
              {{ $t('confirm') }}
            </button>
          </div>
        </vue-modal-2>


        <vue-modal-2 name="ResetPassword" :headerOptions="{ title: 'Reset password' }" :noFooter="true" modalSize="md"
          @on-close="closePopUp('ResetPassword')">
          <div class="flex flex-col items-center justify-center">
            <div class="flex flex-col bg-white px-4 sm:px-6 md:px-8 lg:px-10 py-8 rounded-md w-full max-w-md">

              <div class="login-img">
                <img :src="image" alt="RM" />
              </div>

              <div class="font-medium self-center text-xl sm:text-2xl uppercase text-gray-800">
                {{ $t('Login to your account ') }}
              </div>

              <div class="mt-10">
                <div>
                  <div class="flex flex-col mb-6">
                    <label for="email" class="mb-1 text-xs sm:text-sm tracking-wide text-gray-600">{{ $t('New Password')}}:</label>
                    <div class="relative">
                      <div
                        class="inline-flex items-center justify-center absolute left-0 top-0 h-full w-10 text-gray-400">
                        <svg class="h-6 w-6" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                          viewBox="0 0 24 24" stroke="currentColor">
                          <path
                            d="M12 15v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2zm10-10V7a4 4 0 00-8 0v4h8z" />
                        </svg>
                      </div>

                      <input type="password" name="username" required
                        class="text-sm sm:text-base placeholder-gray-500 pl-10 pr-4 rounded-lg border border-gray-400 w-full py-2 focus:outline-none focus:border-green-500 "
                        v-model="formData.password" :placeholder="$t('New password')" />
                    </div>
                  </div>

                  <div class="flex flex-col mb-6">
                    <label for="password" class="mb-1 text-xs sm:text-sm tracking-wide text-gray-600">{{ $t('Confirm Password')}}:</label>
                    <div class="relative">
                      <div
                        class="inline-flex items-center justify-center absolute left-0 top-0 h-full w-10 text-gray-400">
                        <span>
                          <svg class="h-6 w-6" fill="none" stroke-linecap="round" stroke-linejoin="round"
                            stroke-width="2" viewBox="0 0 24 24" stroke="currentColor">
                            <path
                              d="M12 15v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2zm10-10V7a4 4 0 00-8 0v4h8z" />
                          </svg>
                        </span>
                      </div>

                      <input type="password" name="password" required v-model="formData.confirmPassword"
                        class="text-sm sm:text-base placeholder-gray-500 pl-10 pr-4 rounded-lg border border-gray-400 w-full py-2 focus:outline-none focus:border-green-500 "
                        :placeholder="$t('Confirm password')" />
                    </div>
                  </div>



                  <div class="flex w-full">
                    <button @click="newPassword"
                      class="flex relative loidingconfirm items-center justify-center focus:outline-none text-white text-sm sm:text-base bg-green-700 hover:bg-green-700  rounded py-2 w-full transition duration-150 ease-in"
                      :disabled="loadingVerify">
                      <loading :active.sync="loadingVerify" :is-full-page="fullPage"></loading>
                      <span class="mr-2 uppercase">{{ $t('Continue') }}</span>
                      <span>
                        <svg class="h-6 w-6" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                          viewBox="0 0 24 24" stroke="currentColor">
                          <path d="M13 9l3 3m0 0l-3 3m3-3H8m13 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                        </svg>
                      </span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </vue-modal-2>

        <vue-modal-2 name="smsSend" :headerOptions="{ title: `Validation ${verificationType}` }" :noFooter="true"
          modalSize="md">
          <div class="flex flex-col w-full h-auto">
            <!-- Header -->
            <!-- Modal Content-->
            <div class="bg-white rounded px-2  pb-2 flex flex-col">
              <loading :active.sync="loading" :is-full-page="fullPage"></loading>
              <fieldset>
                <h2 class="h2">Votre code de vérification a été envoyé par {{ verificationType == "sms" ? `numéro de
                  téléphone` : "Email" }} </h2>
                <h3 class="h3">Entrez le code de confirmation ci-dessous </h3>
                <!--<label>Name</label>-->

                <div class="fieldset">
                  <label v-for="(code, index) of myCodesSms" @paste="handlePaste($event, index)" :key="index"
                    class="box"><input class="field" type="text" placeholder="•" v-model="code.code"
                      :name="'code-' + index" /></label>
                </div>


              </fieldset>

              <div class="flex p-2 mt-4">
                <div class="flex-auto flex flex-row-reverse">
                  <button :disabled="loadingVerify" @click="verificationCodeSms()"
                    class="w-1/3 p-3 btn-action rounded bg-blue-600 hover:bg-blue-500 text-white font-semibold focus:outline-none relative loidingconfirm">
                    <loading :active.sync="loadingVerify" :is-full-page="fullPage"></loading> {{ $t('confirm') }}
                  </button>
                  <!-- <button :disabled="loading"  @click="CloseModal('myModalDepot')" class="w-1/3 p-3 mr-4 rounded bg-gray-600 hover:bg-gray-500 text-white font-semibold focus:outline-none">Annuler</button> -->
                </div>
              </div>
            </div>
            <!-- End of Modal Content-->
          </div>
        </vue-modal-2>
      </div>
    </div>
  </div>
</template>

<script>
import image from "@/assets/CODINAFRICA.png";
export default {
  props: {
    loginUser: { type: Function },
  },
  mounted() {
    const fields = document.querySelectorAll(".field");
    fields.forEach((field) => {
      field.addEventListener('input', this.handleInputField);
    });
  },
  data() {
    return {
      formData: {
        forgotPasswdEmail: "",
        verify: {
          code: "",
        },
      },
      image: 'https://storeno.b-cdn.net/rmexpressglob-v1/12-2024/1733757434470.jpeg',
      forgotPasswd: false,
      valideUser: false,
      verificationType: "",
      user: {},
      loading: false,
      loadingVerify: false,
      fullPage: false,
      myCodesSms: [{ code: "" }, { code: "" }, { code: "" }, { code: "" }, { code: "" }]
    };
  },
  methods: {

    handlePaste(event, index) {
      event.preventDefault();
      const pastedText = event.clipboardData.getData("text");
      const codes = pastedText.split('').slice(0, 5); // Split pasted text into characters and take only the first 5
      codes.forEach((char, i) => {
        this.myCodesSms[index + i].code = char; // Assign each character to respective input fields
      });

    },

    getAccess(modelToCheck, accessToCheck) {
      const permissions = this.user.role.permissions;
      for (let i in permissions) {
        let model = permissions[i]["model"];
        let access = permissions[i]["access"];

        if (model == modelToCheck) {
          return access[accessToCheck];
        }
      }
    },
    async newPassword() {
      console.log('new pas-----', this.formData)

      if (this.formData.password) this.formData.password = this.formData.password.trim();
      if (this.formData.confirmPassword) this.formData.confirmPassword = this.formData.confirmPassword.trim();

      if (this.formData.password && this.formData.confirmPassword && this.formData.forgotPasswdEmail && this.formData.verify.code && (this.formData.password == this.formData.confirmPassword)) {
        let data = {
          email: this.formData.forgotPasswdEmail,
          password: this.formData.password,
          code: this.formData.verify.code,
          newPassword: true,
        }

        await this.$recaptchaLoaded()
        // Execute reCAPTCHA with action "login".
        const token = await this.$recaptcha('login');
        //console.log('token-------',token)
        data.captcha = token;

        this.loadingVerify = true;
        const resultat = await this.$server.verificationCode("users", data);
        this.loadingVerify = false;
        console.log('resultat new pass', resultat)
        if (resultat && resultat.status == 200) {
          this.$vm2.close("ResetPassword");;
        } else {
          alert(resultat.content, "warning");
        }
      } else {
        alert("Confirmation password does not match", "warning");
        return false;
      }

    },
    async verificationCodeSms() {

      if (this.myCodesSms.filter(element => { return element.code }).map(el => el.code).length < 5) {
        alert("Le code de vérification est requis ou comporte moins de 5 chiffre");
        return false;
      }

      let data = {
        email: this.formData.forgotPasswdEmail.toLowerCase().trim(),
        verify: {
          code: this.myCodesSms.filter(element => { return element.code }).map(el => el.code).reduce((accum, digit) => (accum * 1) + digit, 0),
        },
      };

      await this.$recaptchaLoaded()
      // Execute reCAPTCHA with action "login".
      const token = await this.$recaptcha('login');
      //console.log('token-------',token)
      data.captcha = token;

      this.loadingVerify = true;
      const res = await this.$server.verificationCodeSms("users", data);
      this.loadingVerify = false;

      if (res.content && res.content._id) this.redirectLinkUser(res, res.content);
      else alert(res.content, "warning");

    },
    async forgotPasswordUser() {
      const data = {
        email: this.formData.forgotPasswdEmail.toLowerCase().trim(),
      };
      this.loadingVerify = true;
      const res = await this.$server.forgotPassword("users", data);
      this.loadingVerify = false;
      if (res && res.content._id) {
        this.valideUser = true;
        this.user = res.content;
      } else {
        this.$vm2.close("myModal");
        alert(this.$t('email_address_incorrect'), "warning");
        this.forgotPasswdEmail = "";
        this.user = {};
      }
    },
    trigger() {
      this.$refs.sendReply.click();
    },
    getDomaine(link) {
      let domain = window.location.hostname;
      let protocol = window.location.protocol;
      if (domain == "localhost") domain = "localhost:8081";
      console.log('domaine=========> ', protocol + "://" + domain + "/" + link)
      window.location.href = "/" + link;
      // this.$router.push("/"+link).catch(()=>{});
    },
    async validateVerificationCode() {
      let data = {
        email: this.formData.forgotPasswdEmail.toLowerCase().trim(),
        verify: {
          code: this.formData.verify.code,
        },
      };

      await this.$recaptchaLoaded()
      // Execute reCAPTCHA with action "login".
      const token = await this.$recaptcha('login');
      //console.log('token-------',token)
      data.captcha = token;

      this.loadingVerify = true;
      const resultat = await this.$server.verificationCode("users", data);
      this.loadingVerify = false;
      console.log('resultat----', resultat)
      // return false
      if (resultat && resultat.status == 200) {
        this.valideUser = false;
        this.$vm2.close("myModal");
        this.$vm2.open("ResetPassword");
        //  let self = this;
        // const token = resultat.headers["X-Auth-Token"];
        // this.$jwtService.saveToken(token);
        // this.valideUser = false;
        //  this.$vm2.close("myModal");
        // alert(this.$t('valide_code'), "success");
        // setTimeout(function() {self.getDomaine('profil');}, 250);
      } else {
        // this.$vm2.close("myModal");
        //this.valideUser = false;
        alert(resultat.content, "warning");
      }
    },
    async login() {

      try {
        // this.$vm2.open("smsSend");
        // return false
        if (!this.formData.username || !this.formData.password) {
          alert("l'email ou le mot de passe est vide", "warning");
          return false
        }
        let data = {
          email: this.formData.username.toLowerCase().trim(),
          username: this.formData.username.trim(),
          password: this.formData.password.trim(),
        };
        await this.$recaptchaLoaded()
        // Execute reCAPTCHA with action "login".
        const token = await this.$recaptcha('login');
        //console.log('token-------',token)
        data.captcha = token;
        //return false
        this.loading = true;
        const res = await this.$server.login("users", data);
        this.loading = false;
        console.log("res=> ", res);


        if (res.content && res.content._id) {
          if (
            res.content.status == "deleted" ||
            res.content.status == "blocked"
          ) {
            alert(this.$t('not_access_account'), "warning");
          } else {

            if (res.headers["Sms"]) {
              this.formData.forgotPasswdEmail = res.content.email;
              this.verificationType = "sms"
              this.$vm2.open("smsSend");

            } else if (res.headers["Email"]) {
              this.formData.forgotPasswdEmail = res.content.email;
              this.verificationType = "email"
              this.$vm2.open("smsSend");

            } else this.redirectLinkUser(res, res.content);

          }
        } else alert(res.content || this.$t('username_pass_incorrect'), "warning");

      } catch (error) {
        alert(error, "warning")
      }

    },
    redirectLinkUser(res, user) {
      let self = this;
      if (user.status == "active") {
        const token = res.headers["X-Auth-Token"];
        this.$jwtService.saveToken(token);
        if (user.type == 'StockManager' || user.type == 'Courier' || user.type == 'Return') setTimeout(function () { self.getDomaine('shippings'); }, 250);
        else if (user.type == 'TeleConsultant' || user.type == 'NRP' || user.type == 'CancelledOrder' || user.type == 'ToRemind') setTimeout(function () { self.getDomaine('orders/confirmation'); }, 250);
        else if (user.type == 'Accountant') setTimeout(function () { self.getDomaine('payments'); }, 250);
        else {
          if (user.type != 'Seller' && user.role.permissions.find(o => o.access.update == true && o.model != 'categories')) setTimeout(function () { self.getDomaine(user.role.permissions.find(o => o.access.update == true && o.model != 'categories').model); }, 250);
          else {
            this.$router.push("home");
            location.reload();
          }

        }
      } else {
        const token = res.headers["X-Auth-Token"];
        this.$jwtService.saveToken(token);
        setTimeout(function () { self.getDomaine('profil'); }, 250);
      }
    },
    landingPage() {
      window.open('https://rmexpressglob.net', '_blank');
    },
    async OpenPopUp() {
      this.$vm2.open("myModal");
    },
    closePopUp(name) {
      this.$vm2.close(name);
      if (name == 'myModal') this.valideUser = false;
    },
    handleInputField({ target }) {
      const fields = document.querySelectorAll(".field");
      const value = target.value.slice(0, 1);
      target.value = value;
      if (value && value.toString().length > 1) return
      const step = value ? 1 : -1;
      const fieldIndex = [...fields].findIndex((field) => field === target);
      const focusToIndex = fieldIndex + step;
      console.log('value-----', value)
      if (focusToIndex < 0 || focusToIndex >= fields.length) return;

      fields[focusToIndex].focus();
      // console.log('myCodesSms-----',this.myCodesSms)
      // console.log('map myCodesSms-----',this.myCodesSms.map(el=>el.code))
      // console.log('dee----',this.myCodesSms.map(el=>el.code).filter(element => {return element }).length)
    }
  },
};
</script>

<style scoped>
fieldset {
  text-align: center;
}

.fieldset {
  position: relative;
  display: grid;
  grid-auto-flow: column;
  justify-content: center;
  column-gap: 12px;
  border-radius: 6px;
  overflow: hidden;
  will-change: transform;
  /* make shadows of inner elements visible */
  padding: 6px;
  margin: -6px;
}

.fieldset::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  transform: translateX(-100%);
}


.box {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 42px;
  width: 42px;
  border-radius: 6px;
  box-shadow: 0 0 6px 1px hsl(240deg 54% 61% / 49%);
  overflow: hidden;
  will-change: transform;
}

.box:focus-within {
  box-shadow: 0 0 6px 1px hsla(240, 54%, 61%, 0.2), 0 0 0 2px hsla(240, 54%, 61%, 0.6);
}

.box::before,
.box::after {
  content: "";
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  border-radius: -6px;
  overflow: hidden;
}

.box::before {
  background: hsl(240, 54%, 97%);
  z-index: 1;
  transition: background-color -450ms cubic-bezier(0.25, 0.01, 0.25, 1);
}

.box::after {
  transform: translateY(100%);
  background-color: -hsl(145, 0%, 42%);
  opacity: 0;
  z-index: 10;
  transition: transform -450ms cubic-bezier(0.25, 0.01, 0.25, 1),
    opacity -450ms cubic-bezier(0.25, 0.01, 0.25, 1), background-color -450ms cubic-bezier(0.25, 0.01, 0.25, 1);
}

.field {
  position: relative;
  border: 0;
  outline: 0;
  font-size: 25.21px;
  line-height: 42px;
  color: hsl(0deg 0% 20%);
  background-color: transparent;
  text-align: center;
  z-index: 100;
}

.label {
  font-size: 14px;
  line-height: 15px;
  text-align: center;
  letter-spacing: 0.01em;
  color: hsl(0, 0%, 20%);
}

.label.is-muted {
  color: #828282;
}

.field::placeholder {
  color: hsl(240, 54%, 87%);
}

.h2 {
  font-family: 'Open Sans', sans-serif;
  font-weight: 800;
  font-size: 15px;
  color: #2C3E50;
  margin: 10px 0;
}

.h3 {
  font-family: 'Open Sans', sans-serif;
  font-weight: 400;
  font-style: italic;
  font-size: 15px;
  color: #666;
  margin-bottom: 20px;
}

dialog[open] {
  animation: appear 0.15s cubic-bezier(0, 1.8, 1, 1.8);
}

dialog::backdrop {
  background: linear-gradient(45deg, rgba(0, 0, 0, 0.5), rgba(54, 54, 54, 0.5));
  backdrop-filter: blur(3px);
}

@keyframes appear {
  from {
    opacity: 0;
    transform: translateX(-3rem);
  }

  to {
    opacity: 1;
    transform: translateX(0);
  }
}

.login-img {
  height: auto;
  width: 157px;
  margin: 0 auto 10px;
  display: block;
  box-sizing: border-box;
  text-align: center;
}
</style>